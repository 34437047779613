import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import React from "react";
import { Colours, Icon, Text } from "react/parkable-components";

type Props = {
    signage: string;
    displayMotorbikeIcon?: boolean;
    displayEvIcon?: boolean;
    iconColour?: Colours;
    backgroundStyles?: StyleProp<ViewStyle>;
    textColour?: Colours;
    displayAsPlainText?: boolean;
};

const BayComponent = (props: Props) => {
    const {
        signage,
        displayMotorbikeIcon,
        displayEvIcon,
        iconColour = Colours.NEUTRALS_WHITE,
        textColour = Colours.NEUTRALS_WHITE,
        backgroundStyles,
        displayAsPlainText,
    } = props;

    if (displayAsPlainText) {
        return <Text small>{signage}</Text>;
    }

    return (
        <View style={[styles.wrapper, backgroundStyles]}>
            <Text numberOfLines={1} ellipsizeMode="tail" style={{...styles.text, color: textColour}}>
                {signage}
            </Text>
            <View style={styles.iconWrapper}>
                {displayMotorbikeIcon ? (
                    <Icon
                        name="motorbike"
                        small
                        style={styles.icon}
                        iconStyle={{
                            color: iconColour,
                        }}
                    />
                ) : (
                    ""
                )}
                {displayEvIcon ? (
                    <Icon
                        name="electricvehicleplug"
                        small
                        style={styles.icon}
                        iconStyle={{
                            color: iconColour,
                        }}
                    />
                ) : (
                    ""
                )}
            </View>
        </View>
    );
};

export default BayComponent;

const styles = StyleSheet.create({
    wrapper: {
        width: 150,
        minHeight: 29,
        backgroundColor: Colours.BLUE_300,
        paddingHorizontal: 9,
        paddingVertical: 4,
        borderRadius: 3,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    text: {
        fontSize: 16,
        lineHeight: 21,
        flexShrink: 1,
    },
    iconWrapper: {
        paddingLeft: 6,
        display: "flex",
        flexDirection: "row",
        columnGap: 6,
    },
    icon: {
        width: 15,
        height: 15,
    },
});
