import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import moment, { Moment } from "moment";
import { Platform, ScrollView, StyleSheet, View } from "react-native";
import Modal from "react-native-modal";
import { Button, Colours, Text, TableRow, DialogRef, Icons } from "react/parkable-components";
import Dialog from "react/components/dialog/Dialog";
import Strings from "../../../constants/localization/localization";
import { connect } from "react-redux";
import { Bay } from "react/model/Bay";
import UserDetailsCard from "../../common/UserDetailsCard";
import { cancelHold, selectAndHoldBay } from "react/redux/actions/parking";
import { startParkingFromReservation } from "react/redux/actions/parking";
import { startParking } from "react/redux/actions/parking";
import { Routes } from "react/navigation/root/root.paths";
import { showAlert } from "react/alerts";
import { IRootReducer } from "react/redux/reducers/main";
import { HoldBayFullResponse } from "react/dto/HoldBayResponse";
import ParkImagesCarousel from "../../common/ParkImagesCarousel";
import InstructionsCard from "../../common/InstructionsCard";
import { Feature, ParkingType } from "react/model/Types";
import AccessGateComponent, { ComponentType } from "../../accessControl/AccessGateComponent";
import { getBay } from "react/redux/actions/parks";
import localizeCurrency from "../../../constants/localization/localizeCurrency";
import SelectedVoucherCard from "../../common/voucher/SelectedVoucherCard";
import { ActivityType, Voucher } from "react/model/Voucher";
import { ParkSessionDTO } from "react/model/ParkSessionDTO";
import { ParkableError } from "react/model/ParkableError";
import { BayActivityDTO } from "react/model/BayActivityDTO";
import { MAX_WIDTH_WEB } from "react/root/container/container";
import HoldBayTableRow from "../HoldBayTableRow";
import Constants from "../../../constants/constants";
import useSafeArea from "../../../hooks/useSafeArea";
import EndSessionReminderBox from "../EndSessionReminderBox";
import { logEvent } from "react/analytics";
import { ParkAvailabilityViewParams } from "../ParkAvailabilityView";
import { getAvailabilityInfo } from "./availabilityInfo";
import { usePark } from "react/api/park";
import { useTerritory } from "react/api/territory/territory.api";
import DriveTimeTableRow from "../DriveTimeTableRow";
import { withNavigationProps } from "react/constants/navigation";
import TandemBayAllocatedAlert from "../../tandemParking/TandemBayAllocatedAlert";
import { createRoute, NavigationProps, useNavigation } from "react/navigation/constants";
import { useUserOptions } from "react/api/userOptions";
import ParkableBaseView from "../../common/ParkableBaseView";
import { CarParkNameRow } from "react/components/widgets/table-rows/car-park-row";
import { useAccessGatesInPark } from "react/api/accessGate/accessGate.api";
import { isCreditCardRequired } from "react/services/parkingPrice.service";
import CasualCharge from "react/components/parkDetails/CasualCharge";
import { SharingPoolRow } from "react/components/parkDetails/sharing-pool/SharingPoolRow";
import { handleFailedTransaction } from "react/constants/ExceptionHandler";
import { useUserRoles } from "react/api/user/user.api";
import { userIsOrgMember } from "react/constants/getUserMember";
import { useParkingPriceForUserInBay } from "react/api/parkingPrice/parksParkingPrice.api";
import { PricePeriodType } from "../../../model/parkingPrice/PricePeriod";
import { BayRow } from "react/components/common/bay/BayRow";
import { useSharingPoolBaysForUser } from "react/api/sharingPool/sharingPool.api";
import { mapBayToBayWithSharingPool } from "react/components/common/bay/util";

function ConfirmStartParkingView(props: Props) {
    const { top } = useSafeArea();
    const navigation = useNavigation();

    const {
        userVehicles,
        userCards,
        feature,
        checkShouldUpdateVehicle,
        currentVehicleId,
        cancelHold,
        selectAndHoldBay,
        startParkingFromReservation,
        startParking,
        holdBay,
        currentSession,
        getBay,
        baysAvailable,
        voucher,
        userId,
        parkId,
    } = props;

    const [didCheckShouldUpdateVehicle, setDidCheckShouldUpdateVehicle] = useState(false);

    const [isCheckingShouldUpdateVehicle, setIsCheckingShouldUpdateVehicle] = useState(false);
    const [isBayFree, setIsBayFree] = useState(false);
    const [isSelectingBay, setIsSelectingBay] = useState(false);

    const [vacateBayDeadline, setVacateBayDeadline] = useState<string | null>(null);
    const [heldBayId, setHeldBayId] = useState<number | null>(props.bay?.id ?? null);
    const [heldBayActivity, setHeldBayActivity] = useState<BayActivityDTO | null>(null);
    const [endHold, setEndHold] = useState<Moment | null>(null);
    const [startParkingError, setErrorStartParking] = useState<ParkableError>();
    const [defaultBay, setDefaultBay] = useState<Bay | undefined>(props.bay ?? undefined);
    const voucherDialogRef = useRef<DialogRef | null>(null);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [count, setCount] = useState(1);
    const [selectedGateId, setSelectedGateId] = useState<undefined | string>(undefined);
    const closeAtMidnightWarningDialogRef = useRef<DialogRef | null>(null);
    const countRef = useRef(count);
    countRef.current = count;
    const { userOptions } = useUserOptions();
    const { userRoles } = useUserRoles();

    // baysAvailable already includes the sharing pool bays.
    const _bay = baysAvailable && heldBayId ? baysAvailable.find((b) => b.id === heldBayId) : undefined;

    // BAC-11650 need to use 2 usePark hooks as bay.organisation is fetched after hold bay flow completes. So baysPark becomes undefined for a second, resulting in a weird jumping of screen.
    const { park: usersPark } = usePark(props.parkId);
    const { park: baysPark } = usePark(props.parkId, _bay?.organisation);
    const { territory } = useTerritory(usersPark?.territory);
    const { gates, isLoading: gatesLoading } = useAccessGatesInPark(baysPark?.id);

    const { bays: sharingPoolBays, isLoading: isLoadingSharingPoolBays } = useSharingPoolBaysForUser(
        baysPark?.organisation,
        baysPark?.id,
        { feature: feature }
    );

    const { data: parkingPriceDto } = useParkingPriceForUserInBay(props.parkId, _bay?.id);
    const availabilityInfo = useMemo((): ParkAvailabilityViewParams | null => {
        if (!usersPark) {
            return null;
        }
        return getAvailabilityInfo(usersPark, heldBayId, vacateBayDeadline);
    }, [usersPark, heldBayId, vacateBayDeadline]);

    useEffect(() => {
        if (!!heldBayActivity && !!endHold && !isOpen) {
            const timer = setInterval(() => {
                setCount((countRef.current + 1) % 4);
                if (!!heldBayActivity && !!heldBayActivity.hold && !!heldBayActivity.hold.startDate) {
                    if (endHold.diff(moment(), "seconds") <= 0) {
                        setIsOpen(true);
                        setHeldBayId(null);
                        setEndHold(null);
                    }
                }
            }, 300);
            return () => clearTimeout(timer);
        }
    }, [heldBayActivity, isOpen, endHold]);

    useEffect(() => {
        if (checkShouldUpdateVehicle) {
            checkShouldUpdateVehicle().then(
                () => {
                    setIsCheckingShouldUpdateVehicle(false);
                    setDidCheckShouldUpdateVehicle(true);
                },
                (err) => {
                    console.log("error on checkShouldUpdateVehicle", err);
                    showAlert(err?.message ?? Strings.internal_error_if_persists, Strings.error);
                    setIsCheckingShouldUpdateVehicle(false);
                }
            );
        }
    }, [!didCheckShouldUpdateVehicle, checkShouldUpdateVehicle]);

    useEffect(() => {
        if (!!currentSession && !!currentSession.bay) {
            (async () => {
                const bay: Bay = await getBay(currentSession.park, currentSession.bay!);
                setDefaultBay(bay);
            })();
        } else {
            if (!!props.bay) {
                setDefaultBay(props.bay);
            }
            console.log("YES WE ARE ATTEMPTING TO HOLD PARK", props.bay);
            !!currentVehicleId && holdPark(props.bay?.id);
        }
    }, [currentVehicleId, userVehicles.length, props.bay, currentSession]);

    /**
     * called after errorStartParking is called
     * */
    useEffect(() => {
        if (startParkingError) {
            if (!handleFailedTransaction(startParkingError, navigation)) {
                showAlert(startParkingError.message ?? Strings.internal_error_if_persists, Strings.error);
            }
        }
    }, [isConfirming, startParkingError]);

    /**
     * on component unmount
     */
    useEffect(() => {
        return () => {
            cancelHold();
        };
    }, []);

    const myVoucher = useRef<Voucher>(voucher);

    useEffect(() => {
        if (
            (!myVoucher.current && !!voucher) ||
            (!!myVoucher.current && !!voucher && myVoucher.current.id != voucher.id)
        ) {
            voucherDialogRef.current?.show();
        }
    }, [voucher, myVoucher, voucherDialogRef]);

    const vehiclesNotNull = useCallback(() => {
        const isVehiclesNullOrEmpty = !userVehicles || userVehicles.length === 0;
        return currentVehicleId && !isVehiclesNullOrEmpty;
    }, [userVehicles, currentVehicleId]);

    const isParkPFB = !!usersPark?.organisation;

    const holdPark = async (bayId?: number) => {
        if (!holdBay) {
            setHeldBayId(props.bay?.id ?? null);
            return props.bay?.id;
        }

        if (isSelectingBay || !isParkPFB) {
            return null;
        }

        setIsSelectingBay(true);
        try {
            //bayFeatureOverride used as we removed the ELECTRIC vehicle type
            //this means we have to force the required type to electric
            let bayFeatureOverride = undefined;
            if (props.parkingType === ParkingType.ELECTRIC) {
                bayFeatureOverride = "ELECTRIC";
            }
            console.log("ABOUT TO HOLD");
            const result: HoldBayFullResponse = await selectAndHoldBay(parkId, bayId, bayFeatureOverride);
            console.log("ABOUT TO HOLD -> RETURNED", result);
            if (result) {
                setVacateBayDeadline(result.vacateBayDeadline);
                setHeldBayId(result.bay.id);
                setHeldBayActivity(result.bayActivity);
                setEndHold(moment().add({ seconds: Constants.holdBayTimeLimit }));
                setIsBayFree(result.isBayFree);
            }
        } catch (err) {
            console.log("Select bay request failed. ", err);
            navigation.pop();
        }
        setIsSelectingBay(false);
    };

    const preRequisitesComplete = useCallback(() => {
        const isVehiclesNotNull = vehiclesNotNull();
        const hasCard = !!userCards && userCards.length > 0;

        const creditCardRequired =
            baysPark &&
            parkingPriceDto?.pricePeriods &&
            isCreditCardRequired(baysPark, parkingPriceDto?.pricePeriods, hasCard, voucher);

        return isVehiclesNotNull && !creditCardRequired;
    }, [baysPark, voucher, userCards, vehiclesNotNull, isBayFree]);

    const isLoading = useCallback(() => {
        return isConfirming || isCheckingShouldUpdateVehicle || isSelectingBay;
    }, [isConfirming, isCheckingShouldUpdateVehicle, isSelectingBay]);

    const onConfirmStartParkingPress = (
        gateId: string | undefined,
        warningMidnightConfirmed: boolean,
        estimatedLeaveTime: string | null
    ) => {
        console.log("-------------------- heldBay IS:", heldBayId);
        setSelectedGateId(gateId);

        if (availabilityInfo!.closesAtMidnightTonight && availabilityInfo!.bonusBayStyle && !warningMidnightConfirmed) {
            closeAtMidnightWarningDialogRef.current?.show();
            return;
        }

        const disabled = !preRequisitesComplete() || (!currentSession && isParkPFB && !heldBayId);

        console.log("disabled = ", disabled, preRequisitesComplete(), isParkPFB, heldBayId);

        console.log("isBayFree", isBayFree);
        if (isLoading() || disabled) {
            console.log("leaving early");
            return;
        }

        setIsConfirming(true);

        const paramsEvent = { userId: `${userId ?? 0}`, parkId: `${parkId}` };
        logEvent(undefined, "confirmdetails_confirm", paramsEvent);

        const onSuccess = async (session: ParkSessionDTO | null) => {
            if (!!session) {
                await finishStartParking(session);
            }
        };

        const userHasCards = userCards?.length > 0;

        if (!!currentSession) {
            startParkingFromReservation(
                currentSession.id,
                selectedGateId,
                territory,
                userHasCards,
                estimatedLeaveTime,
                onSuccess,
                errorStartParking
            );
        } else {
            startParking(
                parkId,
                heldBayId ?? undefined,
                false,
                selectedGateId,
                territory,
                userHasCards,
                estimatedLeaveTime,
                onSuccess,
                errorStartParking
            );
        }
    };

    const handleConfirmButtonPress = (gateId: string | undefined, warningMidnightConfirmed: boolean) => {
        if (defaultBay?.tandemPod || _bay?.tandemPod) {
            navigation.push(Routes.ConfirmStartTandemParkingView, {
                parkId: (defaultBay?.park || _bay?.park)!,
                onConfirmTandemParking: (estimatedLeaveTime: string | null) =>
                    onConfirmStartParkingPress(gateId, warningMidnightConfirmed, estimatedLeaveTime),
            });
        } else {
            onConfirmStartParkingPress(gateId, warningMidnightConfirmed, null);
        }
    };

    const showRetryPaymentView = useCallback((confirmed: boolean, sessionId: number) => {
        setErrorStartParking(undefined);

        if (!confirmed) {
            return;
        }
        navigation.push(Routes.RetryPaymentRequest, { sessionId });
    }, []);

    const errorStartParking = useCallback(
        (err: any) => {
            console.log("Error on start parking ", err);
            //navigation.pop();

            setIsConfirming(false);
            /*
             * hook is called after this [isConfirming, startParkingError]
             * */
            setErrorStartParking(err);
        },
        [navigation]
    );

    const showActiveSessionView = () => {
        navigation.reset({
            routes: [
                {
                    name: Routes.ActiveSession,
                },
            ],
        });
    };

    const showParkAvailabilityView = () => {
        if (availabilityInfo) {
            navigation.push(Routes.ParkAvailabilityView, availabilityInfo);
        }
    };

    const finishStartParking = (session: ParkSessionDTO) => {
        setIsConfirming(false);

        if (!gates?.length && props.parkingType === ParkingType.ELECTRIC && props.bay) {
            navigation.navigate(Routes.ConnectEVNozzle, {
                bayId: props.bay.id,
                parkId: props.parkId,
                sessionId: session.id,
                overrideBackButton: true,
            });
        } else {
            showActiveSessionView();
        }
    };

    const setBaySelected = (bay: Bay) => {
        if (bay && bay.id !== heldBayId) {
            const previousHeldBayId = heldBayId;
            setHeldBayId(null);
            setIsSelectingBay(true);
            holdPark(bay.id).catch((err) => {
                console.log("error on hold bay", err);
                if (!!previousHeldBayId) {
                    setHeldBayId(previousHeldBayId);
                }
            });
        }
    };

    const retryHoldBay = useCallback(async () => {
        if (!isSelectingBay) {
            const response = await holdPark(props.bay?.id);
            if (!!response) {
                setIsOpen(false);
            } else {
                showAlert(Strings.sorry_no_other_available_bays, Strings.error);
            }
        }
    }, [currentVehicleId, props.bay, isSelectingBay]);

    const closeModal = () => {
        setIsOpen(false);
        navigation.pop();
    };

    const navigateToNotificationOptions = useCallback(() => {
        navigation.push(Routes.NotificationSettingsView);
    }, ["once"]);

    let buttonText = Strings.confirm;

    const confirmButtonDisabled =
        !preRequisitesComplete() ||
        (!currentSession && isParkPFB && !heldBayId) ||
        (!!currentSession && !!currentSession.bay && !defaultBay);
    const isSharingPool =
        heldBayActivity?.hold.isSharingPool && userIsOrgMember(userRoles, usersPark?.organisation);

    return (
        <ParkableBaseView scrollable={false} loading={isConfirming}>
            <View style={{ flex: 1 }}>
                <Text h1>{Strings.confirm_details}</Text>
                <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
                    {isSharingPool && <SharingPoolRow />}

                    <TandemBayAllocatedAlert
                        bay={defaultBay || _bay}
                        onConfirmTandemParking={(estimatedLeaveTime: string | null) =>
                            onConfirmStartParkingPress(undefined, false, estimatedLeaveTime)
                        }
                    />

                    <EndSessionReminderBox
                        park={usersPark}
                        userOptions={userOptions}
                        navigateToNotificationOptions={navigateToNotificationOptions}
                    />

                    {availabilityInfo && (
                        <TableRow
                            style={{ alignContent: "flex-start" }}
                            chevron
                            iconLeft={"parkingfilled"}
                            disabled={availabilityInfo.disabled}
                            onPress={showParkAvailabilityView}
                        >
                            {availabilityInfo.info}
                        </TableRow>
                    )}

                    <BayRow
                        bay={defaultBay || _bay}
                        parkId={parkId}
                        onChange={setBaySelected}
                        baysAvailable={mapBayToBayWithSharingPool(baysAvailable, sharingPoolBays)}
                        parkingType={props.parkingType}
                        loading={isLoadingSharingPoolBays}
                    />

                    <UserDetailsCard hideCreditCard />

                    {territory && (
                        <CasualCharge
                            bay={defaultBay || _bay}
                            parkingType={ParkingType.CASUAL}
                            park={baysPark}
                            territory={territory}
                            parkingPrice={parkingPriceDto?.id}
                        />
                    )}

                    <UserDetailsCard hideVehicle />

                    <CarParkNameRow displayName={usersPark?.displayName} />

                    <DriveTimeTableRow
                        showDriveTime={false}
                        endLatitude={usersPark?.latitude}
                        endLongitude={usersPark?.longitude}
                        park={usersPark || null}
                        showLocationLabel={true}
                    />

                    {props.parkingType === ParkingType.ELECTRIC && (
                        <TableRow
                            label={Strings.electricity_price}
                            textProps={{ small: true }}
                            iconLeft={"electricvehicleplug"}
                        >
                            {localizeCurrency(
                                parkingPriceDto?.pricePeriods.find((pp) => pp.type === PricePeriodType.KwH)?.price ??
                                    _bay?.pricePerKwh ??
                                    baysPark?.pricePerKwh ??
                                    0,
                                territory?.currencyCode,
                                false
                            ) +
                                " " +
                                Strings.perKwh}
                        </TableRow>
                    )}

                    {baysPark && (
                        <SelectedVoucherCard
                            territory={territory}
                            voucher={voucher}
                            park={baysPark}
                            activity={ActivityType.Casual}
                        />
                    )}

                    <Text h2 style={{ marginTop: 29, marginBottom: 18 }}>
                        {Strings.how_to_park_here}
                    </Text>

                    {baysPark && <ParkImagesCarousel park={baysPark} />}

                    {baysPark && <InstructionsCard park={baysPark} />}
                </ScrollView>

                <View style={styles.bottomBtn}>
                    {!!heldBayActivity && !!endHold && (
                        <HoldBayTableRow bayActivity={heldBayActivity} endHold={endHold} />
                    )}
                    {baysPark && availabilityInfo && (
                        <AccessGateComponent
                            showEntranceGates={true}
                            componentType={ComponentType.Button}
                            buttonDisabled={confirmButtonDisabled}
                            buttonIcon={"arrowboldright"}
                            buttonText={buttonText}
                            onSelectGate={(gateId: string | undefined) => handleConfirmButtonPress(gateId, false)}
                            selectOnly={true}
                            parkId={parkId}
                            displayModalWhenNotInRange={false}
                        />
                    )}
                    {!gatesLoading && !gates?.length && availabilityInfo && (
                        <Button
                            onPress={() => handleConfirmButtonPress(undefined, false)}
                            disabled={confirmButtonDisabled}
                            iconRight={"arrowboldright"}
                        >
                            {buttonText}
                        </Button>
                    )}
                </View>
            </View>
            <Modal animationIn={"bounceIn"} animationOut={"fadeOut"} isVisible={isOpen} style={styles.modal}>
                <View style={styles.openView}>
                    <Text h2 style={{ marginBottom: 0 }}>
                        {Strings.sorry_times_up}
                    </Text>
                    <View style={styles.lineBreak} />
                    <Text small style={styles.textLine}>
                        {Strings.time_limit_hold_bay}
                    </Text>
                    <Text bold>{Strings.now_what}</Text>
                    <Text small>{Strings.try_again_to_hold_bay}</Text>
                    <View style={styles.modalButtonView}>
                        <Button
                            form
                            center
                            plain
                            border
                            onPress={closeModal}
                            style={{ flex: 1, marginHorizontal: 4.5 }}
                        >
                            {Strings.close}
                        </Button>
                        <Button form center onPress={retryHoldBay} style={{ flex: 1, marginHorizontal: 4.5 }}>
                            {isSelectingBay ? Strings.loading : Strings.try_again}
                        </Button>
                    </View>
                </View>
            </Modal>
            <Dialog
                style={[{ top }, styles.voucherDialog]}
                ref={voucherDialogRef}
                icon={Icons.tick}
                iconProps={{ white: true }}
                label={Strings.voucher_applied}
            />
            <Dialog
                ref={closeAtMidnightWarningDialogRef}
                title={Strings.need_to_leave_this_parking_bay_by_midnight_tonight_warning_message}
                titleProps={{ style: { textAlign: "left" }, h2: undefined }}
                label={Strings.bay_available_till_midnight_tonight}
                labelProps={{ style: { color: Colours.NEUTRALS_BLACK, textAlign: "left" } }}
                positiveText={Strings.confirm}
                positiveProps={{
                    textProps: { h5: true, style: { color: Colours.NEUTRALS_BLACK } },
                    style: { backgroundColor: Colours.ORANGE },
                }}
                negativeProps={{ textProps: { h5: true, style: { color: Colours.NEUTRALS_BLACK } } }}
                negativeText={Strings.go_back}
                onPositivePress={() => onConfirmStartParkingPress(selectedGateId, true, null)}
                onNegativePress={() => closeAtMidnightWarningDialogRef.current?.hide()}
            />
        </ParkableBaseView>
    );
}

export class ConfirmStartParkingParams {
    parkId: number;
    feature: Feature;
    holdBay: boolean;
    checkShouldUpdateVehicle?: () => Promise<any>;
    bay?: Bay;
    parkingType: ParkingType;
    voucher?: Voucher;
}

const mapStateToProps = (state: IRootReducer, props: NavigationProps<Routes.ConfirmStartParkingView>) => {
    const parkId = props.route.params.parkId;

    return {
        parkId,
        feature: props.route.params.feature,
        checkShouldUpdateVehicle: props.route.params.checkShouldUpdateVehicle,
        holdBay: props.route.params.holdBay,
        bay: props.route.params.bay,
        baysAvailable: state.confirmStartParkingView.baysAvailableForVehicle,
        parkingType: props.route.params.parkingType,
        api: state.data.api,
        currentSession: state.parking.currentSession,
        userCards: state.user.cards,
        currentCardId: state.user.currentCardId,
        currentVehicleId: state.user.currentVehicleId,
        userVehicles: state.user.vehicles,
        voucher: state.user?.casualVoucher?.[parkId],
        userId: state.user.user?.id,
    };
};

const mapDispatchToProps = {
    cancelHold,
    selectAndHoldBay,
    startParkingFromReservation,
    startParking,
    getBay,
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(withNavigationProps(ConfirmStartParkingView));

export const ConfirmStartParkingRoute = createRoute({
    path: Routes.ConfirmStartParkingView,
    params: { type: ConfirmStartParkingParams },
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left",
    },
    imageScrollView: {
        marginTop: 27,
        height: 120,
        width: "100%",
    },
    bottomBtn: {
        paddingBottom: 28,
        paddingTop: 9,
    },
    modal: {
        backgroundColor: "rgba(0,0,0,0.1)",
        ...Platform.select({
            web: {
                maxWidth: MAX_WIDTH_WEB,
                alignSelf: "center",
            },
        }),
    },
    openView: {
        borderWidth: 1,
        borderColor: Colours.GREY_BORDER,
        borderRadius: 3,
        padding: 18,
        backgroundColor: Colours.NEUTRALS_WHITE,
        marginHorizontal: 9,
        marginVertical: 28,
    },
    modalButtonView: {
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 27,
    },
    textLine: {
        marginBottom: 18,
    },
    lineBreak: {
        height: 1,
        width: "100%",
        marginBottom: 18,
        backgroundColor: Colours.GREY_BORDER,
    },
    voucherDialog: {
        position: "absolute",
        width: "100%",
    },
    sharingPoolRowContainer: {
        marginBottom: 9,
    },
    sharingPoolRowText: {
        maxWidth: 120,
        alignContent: "flex-start",
    },
});
