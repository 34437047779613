import Text from "react/parkable-components/text/Text";
import Button from "react/parkable-components/button/Button";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Colours from "react/parkable-components/styles/Colours";
import React, { useEffect, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { ParkSession } from "../../model/ParkSession";
import { getSession } from "../../redux/actions/parking";
import { IRootReducer } from "../../redux/reducers/main";
import Strings from "../../constants/localization/localization";
import { ChargeDetails } from "react/components/parkDetails/sessionSummary/ChargeDetails";
import { showAlert, showConfirmation } from "../../alerts";
import AccountCreditCardsCardItem from "../common/cardItems/AccountCreditCardsCardItem";
import { completePayment, restartPayment } from "../../api/parking";
import { NavigationProps } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import { initStripe, useConfirmPayment, useStripe } from "@stripe/stripe-react-native";
import { getDefaultPaymentSourceForPaymentIntent } from "../../redux/actions/user";
import { usePublicStripeApiKey } from "../../api/stripe";
import ParkableBaseView from "../common/ParkableBaseView";
import { CarParkNameRow } from "../widgets/table-rows/car-park-row";
import { useAppDispatch } from "../../redux/redux";
import * as PaymentIntent from "@stripe/stripe-react-native/src/types/PaymentIntent";
import { refreshSessionHistory, useParkSessionV3 } from "react/api/parkSession/parkSession.api";
import {
    retryPayment as retryPaymentAPI,
    useFailedPayment,
    usePaymentsDetail,
    usePaymentsRequiringAuthentication,
} from "react/api/stripe/stripe.api";
import { usePark } from "react/api/park";
import { useTerritory } from "react/api/territory/territory.api";
import moment from "moment";
import { SessionCharges } from "../parkDetails/sessionSummary/SessionCharges";
import { getEmployeeSubscriptionsByUser } from "react/redux/actions/subscriptions";
import { PaymentDetails } from "react/api/stripe/dto/PaymentDetails";
import { RetryPaymentRequest } from "react/api/stripe/dto/RetryPaymentRequest";
import { ParkSessionDTO } from "react/model/ParkSessionDTO";

const styles = StyleSheet.create({
    contentStyle: {
        padding: 18,
    },
    header: {
        paddingBottom: 27,
    },
    buttonRow: {
        flexDirection: "row",
        width: "100%",
        position: "relative",
        paddingBottom: 18,
        paddingHorizontal: 18,
        marginTop: 20,
    },
    button1: {
        flex: 1,
    },
    receipt: {},
});

type Props = ReturnType<typeof getProps> & typeof actions & NavigationProps<Routes.RetryPaymentRequest>;

const dateFormat = "DD MMM YYYY";
const sessionDateFormat = "hh:mma, dddd DD MMMM YYYY";

function RetryPaymentMobile(props: Props) {
    const {
        callback,
        getSession,
        sessionId,
        getEmployeeSubscriptionsByUser,
        finishParkingData,
        api,
        token,
        navigation,
    } = props;
    const dispatch = useAppDispatch();

    const { failedPayments: paymentsRequiringAuthentication, mutate } = usePaymentsRequiringAuthentication();
    const { data, mutate: mutateFailedPayment } = useFailedPayment();

    const failedPayment = paymentsRequiringAuthentication?.[0] ?? data; //Always get the first one;
    const entityIdAndType = useMemo(() => {
        if (!failedPayment) {
            return null;
        }

        if (failedPayment.sessionId) {
            return {
                id: failedPayment.sessionId,
                type: "ParkSession",
            };
        } else if (failedPayment.depositId) {
            return {
                id: failedPayment.depositId,
                type: "Deposit",
            };
        } else if (failedPayment.infringementId) {
            return {
                id: failedPayment.infringementId,
                type: "Infringement",
            };
        } else if (failedPayment.stripeChargeId) {
            return {
                id: failedPayment.stripeChargeId,
                type: "StripeCharge",
            };
        } else if (failedPayment.stripeSubscriptionInvoiceId) {
            return {
                id: failedPayment.stripeSubscriptionInvoiceId,
                type: "StripeSubscriptionInvoice",
            };
        }
        return null;
    }, [failedPayment]);
    const { parkSession: session } = useParkSessionV3(
        sessionId ?? (entityIdAndType?.type === "ParkSession" ? entityIdAndType?.id : null)
    );
    const { data: entity } = usePaymentsDetail(entityIdAndType?.id, entityIdAndType?.type);
    const { park } = usePark(entity?.parkId ?? session?.park);
    const { territory } = useTerritory(park?.territory);

    const [buttonLoading, setButtonLoading] = useState(false);
    const [stripeLoading, setStripeLoading] = useState(true);
    const { data: stripeApiKey } = usePublicStripeApiKey(park?.organisation ?? undefined);
    const { confirmPayment } = useConfirmPayment();
    const { retrievePaymentIntent } = useStripe();

    useEffect(() => {
        async function initialize() {
            const publishableKey = stripeApiKey?.stripePublicApiKey;
            if (publishableKey) {
                await initStripe({ publishableKey });
                setStripeLoading(false);
            }
        }
        if (stripeApiKey) {
            initialize().catch(console.log);
        }
    }, [stripeApiKey]);

    const dateFormatted = useMemo(() => {
        if (!entity) {
            return null;
        }
        const date = moment(entity?.date || undefined);
        return date.clone().format(dateFormat);
    }, [entity]);

    const startedAtFormatted = useMemo(() => {
        if (!session) {
            return null;
        }
        const startedAt = moment(session?.startedAt || undefined);
        return startedAt.clone().format(sessionDateFormat);
    }, [session]);

    const endedAtFormatted = useMemo(() => {
        if (!session) {
            return null;
        }
        const endedAt = moment(session?.endedAt || undefined);
        return endedAt.clone().format(sessionDateFormat);
    }, [session]);

    const paymentType = useMemo(() => {
        if (!entity) {
            return null;
        }

        switch (entity.entityType) {
            case "ParkSession":
                return Strings.casual_session;
            case "Deposit":
                return Strings.deposit;
            case "Infringement":
                return Strings.infringement;
            case "StripeCharge":
                return entity.transactionReference;
            case "StripeSubscriptionInvoice":
                return Strings.subscription;
            default:
                return null;
        }
    }, [entity]);

    const finaliseRecoveryFlow = async (paymentIntent: PaymentIntent.Result) => {
        if (!entity) {
            return;
        }

        const onError = () => {
            showConfirmation(Strings.auth_failure_explanation, Strings.payment_failed, false);
            setButtonLoading(false);
        };

        if (paymentIntent.status === "Succeeded") {
            //show receipt
            const finaliseResult = await completePayment(api, token, entity, paymentIntent.id);
            await mutate();

            if (!!finaliseResult) {
                //reload these to remove from redux
                showConfirmation(Strings.payment_successful, Strings.success_exclaim, false, () => {
                    callback?.(finaliseResult.parkSession);
                    navigation.pop();
                });
                if (!!session) {
                    await refreshSessionHistory();
                } else if (entity.entityType == "StripeSubscriptionInvoice") {
                    getEmployeeSubscriptionsByUser();
                }
            }
        } else if (paymentIntent.status === "Canceled") {
            console.log("Going to do restart flow");
            //The last authorization attempt did not complete, please wait a moment while we recover
            await mutate();

            const response = await restartPayment(api, token, entity, paymentIntent.id);

            console.log("restart flow returned", response);
            if (!!response) {
                //@ts-ignore
                doRecoveryFlow(response.paymentIntentClientSecretId);
            } else {
                onError();
            }
        } else {
            onError();
        }
    };

    const onClick = () => {
        if (!entity && !session) {
            //still loading
            return;
        }

        setButtonLoading(true);
        if (!!entity && entity.transactionResponse === "authentication_required" && !!entity.paymentIntentClientId) {
            doRecoveryFlow(entity.paymentIntentClientId);
        } else if (entity) {
            //Retry Payment
            retryPayment(entity!);
        }
    };

    const retryPayment = async (entity: PaymentDetails) => {
        const request: RetryPaymentRequest = {};

        switch (entity.entityType) {
            case "ParkSession":
                request.sessionId = parseInt(entity.entityId);
                break;
            case "Deposit":
                request.depositId = parseInt(entity.entityId);
                break;
            case "Infringement":
                request.infringementId = parseInt(entity.entityId);
                break;
            case "StripeCharge":
                request.stripeChargeId = parseInt(entity.entityId);
                break;
            case "StripeSubscriptionInvoice":
            default:
                request.stripeSubscriptionInvoiceId = entity.entityId;
                break;
        }

        try {
            const response = await retryPaymentAPI(request);

            if (request.sessionId) {
                getSession(request.sessionId!, (parkSession) => {
                    retryPaymentCallback(
                        response.data.transactionSuccess,
                        response.data.transactionResponse,
                        parkSession
                    );
                });
            } else {
                retryPaymentCallback(response.data.transactionSuccess, response.data.transactionResponse, null);
            }
        } catch (error: any) {
            console.log("error on retry payment flow", error);
            setButtonLoading(false);
            retryPaymentCallback(false, error?.message, null);
        }
    };

    const doRecoveryFlow = async (paymentIntentClientId: string) => {
        if (!stripeApiKey || stripeLoading) {
            return;
        }
        try {
            const { paymentIntent, error } = await retrievePaymentIntent(paymentIntentClientId);

            if (!paymentIntent || error) {
                console.log("error on retrievePaymentIntent", error);
                showAlert(Strings.sorry_we_could_not_find_the_required_payment_information, Strings.retry_transaction);
                setButtonLoading(false);
                return;
            }
            const paymentMethod = await dispatch(getDefaultPaymentSourceForPaymentIntent(paymentIntent.id));

            if (paymentIntent.status == "Succeeded") {
                await finaliseRecoveryFlow(paymentIntent);
                return;
            }

            const result = await confirmPayment(paymentIntentClientId, {
                paymentMethodType: "Card",
                paymentMethodData: { paymentMethodId: paymentMethod.paymentMethod },
            });

            if (!!result.error) {
                console.log("error on confirmPayment", result.error);
                showAlert(result.error.localizedMessage ?? result.error.message, Strings.retry_transaction);
                setButtonLoading(false);
                return;
            }

            await finaliseRecoveryFlow(result.paymentIntent!);
        } catch (error) {
            console.log("error on Recovery flow", error);
            showAlert(Strings.sorry_we_could_not_find_the_required_payment_information, Strings.retry_transaction);
            setButtonLoading(false);
        }
    };

    const retryPaymentCallback = async (
        success: boolean,
        message: string | null,
        entity: ParkSession | ParkSessionDTO | null
    ) => {
        await mutateFailedPayment();
        if (success) {
            setButtonLoading(false);
            showConfirmation(Strings.payment_successful, Strings.success, false, () => {
                if (!!callback && typeof callback === "function") {
                    callback(entity);
                }
                navigation.pop();
            });
        } else {
            if (
                !!entity &&
                entity.transactionResponse === "authentication_required" &&
                !!entity.paymentIntentClientId
            ) {
                doRecoveryFlow(entity.paymentIntentClientId);
            } else {
                setButtonLoading(false);
                showConfirmation(
                    message ?? "",
                    Strings.we_were_unable_to_process_your_payment_please_check_your_payment_details_and_try_again,
                    false
                );
            }
        }
    };

    const currencyCode = territory?.currencyCode ?? "NZD";
    const buttonText = entity?.transactionResponse === "authentication_required" ? Strings.authenticate : Strings.retry;
    const buttonDisabled = buttonLoading || (!session && !entity) || !(stripeApiKey && park);

    const loading = (!entity && !session) || stripeLoading;

    return (
        <ParkableBaseView loading={loading} style={{ flex: 1 }} showToolbar showBackButtonOnToolbar>
            <View>
                <View style={styles.header}>
                    <Text h1>
                        {entity?.transactionResponse === "authentication_required"
                            ? Strings.authenticate_transaction
                            : Strings.retry_transaction}
                    </Text>
                    <Text>
                        {entity?.transactionResponse === "authentication_required"
                            ? Strings.re_authenticate_instructions
                            : Strings.please_find_details_below}
                    </Text>
                </View>
                <View style={styles.receipt}>
                    <Text h2>{Strings.details}</Text>
                    {dateFormatted && (
                        <TableRow
                            iconLeft={"calendar"}
                            label={Strings.payment_date}
                        >
                            {dateFormatted}
                        </TableRow>
                    )}
                    {paymentType && (
                        <TableRow
                            iconLeft={"dollarfilled"}
                            label={Strings.payment_type}
                        >
                            {paymentType}
                        </TableRow>
                    )}
                    {session ? (
                        <SessionCharges
                            currencyCode={currencyCode}
                            parkSession={session}
                            finishParkingData={finishParkingData}
                        />
                    ) : (
                        entity && <ChargeDetails currencyCode={currencyCode} entity={entity} />
                    )}
                    <CarParkNameRow displayName={park?.displayName} />
                    <TableRow
                        iconLeft={"pinlocation2filled"}
                        label={Strings.location}
                    >
                        <Text small numberOfLines={2}>
                            {park?.address}
                        </Text>
                    </TableRow>
                    {startedAtFormatted && (
                        <TableRow
                            iconLeft={"stopwatchfilled"}
                            label={Strings.session_started}
                        >
                            {startedAtFormatted}
                        </TableRow>
                    )}
                    {endedAtFormatted && (
                        <TableRow
                            iconLeft={"tickfilled"}
                            label={Strings.session_ended}
                        >
                            {endedAtFormatted}
                        </TableRow>
                    )}
                    <AccountCreditCardsCardItem />
                </View>
            </View>
            {!(entity ? entity.transactionSuccess : session?.transactionSuccess) && (
                <View style={styles.buttonRow}>
                    <Button
                        disabled={buttonDisabled}
                        center
                        loading={buttonLoading}
                        textProps={{ h3: true }}
                        style={styles.button1}
                        onPress={onClick}
                    >
                        {buttonLoading ? undefined : buttonText}
                    </Button>
                </View>
            )}
        </ParkableBaseView>
    );
}

const actions = {
    getEmployeeSubscriptionsByUser,
    getSession,
};

const getProps = (state: IRootReducer, props: NavigationProps<Routes.RetryPaymentRequest>) => {
    const sessionId = props.route.params?.sessionId;
    const callback = props.route.params?.callback;

    const tokenObject = {
        firebaseToken: state.auth.fireBaseToken,
        parkableToken: state.data.token,
    };

    return {
        sessionId,
        finishParkingData: state.parking.finishParkingData,
        api: state.data.api,
        token: tokenObject,
        callback,
    };
};

export default connect(getProps, actions)(RetryPaymentMobile);
