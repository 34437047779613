import Text from "react/parkable-components/text/Text";
import Button from "react/parkable-components/button/Button";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Colours from "react/parkable-components/styles/Colours";
import moment from "moment";
import React from "react";
import { Image, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { showSaveFavouriteModal } from "../../../redux/actions/userOptions";
import { setFinishedParkingData } from "../../../redux/actions/parking";
import { IRootReducer } from "../../../redux/reducers/main";
import { Routes } from "react/navigation/root/root.paths";
import SaveFavourite from "../SaveFavourite";
import Strings from "../../../constants/localization/localization";
import AccessGateComponent, { ComponentType } from "../../accessControl/AccessGateComponent";
import Constants from "../../../constants/constants";
import { SessionCharges } from "./SessionCharges";
import LoadingOverlay_new from "../../common/LoadingOverlay_new";
import { useTerritory } from "../../../api/territory/territory.api";
import { getId } from "../../../constants/Util";
import { useParkSessions } from "../../../api/parkSession/parkSession.api";
import { useTandemPod } from "../../../api/tandem/tandem.api";
import AppReview from "../../common/AppReview";
import { useBay } from "../../../api/bay/bay.api";
import { usePark } from "../../../api/park/park.api";
import { useUserVehicles } from "../../../api/vehicle/vehicle.api";
import { useUserCards } from "../../../api/stripe/stripe.api";
import { createRoute, NavigationProps } from "../../../navigation/constants";
import useSafeArea from "../../../hooks/useSafeArea";
import ParkableBaseView from "../../common/ParkableBaseView";
import { PADDING } from "../../../root/root.constants";
import { CarParkNameRow } from "react/components/widgets/table-rows/car-park-row";
import { useUserOptions } from "../../../api/userOptions";
import BayComponent from "react/components/common/bay/BayComponent";
import { getBayFeature, getBayTileColour } from "react/components/common/bay/util";

const MaskGroup = require("../../../resources/Mask-Group-15478.png");

const styles = StyleSheet.create({
    base: {
        backgroundColor: Colours.NEUTRALS_WHITE,
        flex: 1,
    },
    contentStyle: {
        padding: 18,
    },
    header: {
        paddingBottom: 27,
        paddingHorizontal: PADDING,
    },
    buttonRow: {
        flexDirection: "row",
        width: "100%",
        position: "relative",
        paddingBottom: 45,
        marginTop: 20,
    },
    button1: {
        flex: 0.66,
    },
    button2: {
        flex: 1,
        marginLeft: 9,
    },
    receipt: {},
    maskGroupImage: {
        flex: 1,
        aspectRatio: 375 / 168,
        zIndex: 1,
    },
});

type SessionSummaryProps = ReturnType<typeof getProps> & typeof actions & NavigationProps<Routes.SessionSummary>;

const sessionDateFormat = "hh:mma, dddd DD MMMM YYYY";

function SessionSummary(props: SessionSummaryProps) {
    const { bottom } = useSafeArea();
    const { currentSession, navigation, favouriteParks, setFinishedParkingData, showSaveFavouriteModal } = props;

    const { parkSessions } = useParkSessions(1);

    const session = currentSession ?? (parkSessions?.length ? parkSessions[0] : undefined);

    const parkId: number | undefined = getId(session?.park);
    const { park } = usePark(parkId);
    const { bay } = useBay(parkId, session?.bay);
    const { userOptions } = useUserOptions();
    const { tandemPod } = useTandemPod(bay?.tandemPod);
    const { territory } = useTerritory(park?.territory);
    const { vehicles } = useUserVehicles();
    const { cards, currentCardId } = useUserCards();
    const card = cards?.find((c) => c.id === currentCardId);

    if (!session || !bay) {
        return <LoadingOverlay_new loading={true} />;
    }

    const registration: string | undefined = vehicles?.filter((v) => !v.deleted && v.id === getId(session.vehicle))?.[0]
        ?.registration;

    const isFavourite: boolean = !!Object.values(favouriteParks || {}).find((p) => p.id === park?.id);

    const address = park?.address ?? "";

    const startedAtFormatted = moment(session.startedAt || undefined).format(sessionDateFormat);

    const endedAtFormatted = moment(session.endedAt || undefined).format(sessionDateFormat);

    const currencyCode = territory?.currencyCode ?? "NZD";

    const onDonePress = () => {
        setFinishedParkingData({}); //clear ready for next session

        navigation.reset({
            routes: [
                {
                    name: Routes.ParkableMapView,
                },
            ],
        });
    };

    const onSaveFavouritePark = () => {
        showSaveFavouriteModal(true);
    };

    type Params = {
        result: boolean;
        details: any;
    };
    const saveFavouriteCallback = (params: Params) => {
        params.result && onDonePress(); //close on finish
    };

    const retryPayment = () => {
        if ((session.transactionAmount ?? 0) > 0 && !session.transactionSuccess) {
            navigation.push(Routes.RetryPaymentRequest, { sessionId: session.id });
        }
    };

    const navigateToTandemChat = () => {
        navigation.push(Routes.TandemChatView, {
            sessionId: session.id,
            tandemPodId: bay.tandemPod ?? undefined,
        });
    };

    const { isEV, isMotorbike } = getBayFeature([bay]);
    const backgroundColor = getBayTileColour(true, false, currentSession?.isSharingPool);

    //on iphone with 320 width screen, nudge the letter spacing to avoid ugly looking line break on button
    const screenWidth = Constants.screenWidth;
    const widthFixTextStyle = Platform.OS === "ios" && screenWidth === 320 ? { letterSpacing: -0.05 } : undefined;
    return (
        <ParkableBaseView removeStandardMargins toolbarStyle={{ marginLeft: PADDING }} onBackPress={onDonePress}>
            <View style={styles.base}>
                <View style={styles.header}>
                    <Text h1>{Strings.thanks_for_using_parkable}</Text>
                    {bay.tandemPod && (
                        <View style={{ marginBottom: 18 }}>
                            <TableRow
                                hideBorder
                                chevron
                                backgroundColor={Colours.ORANGE}
                                onPress={navigateToTandemChat}
                            >
                                {Strings.tandem_parking.view_tandem_chat}
                            </TableRow>
                        </View>
                    )}
                    <Text>{Strings.please_find_details_below}</Text>
                </View>

                <View style={{ flexDirection: "row" }}>
                    <Image style={styles.maskGroupImage} source={MaskGroup} />
                </View>
                <View style={{ paddingHorizontal: PADDING }}>
                    {park && (
                        <AccessGateComponent
                            showExitGates={true}
                            parkId={park.id}
                            centreButton={true}
                            buttonText={Strings.open_gate}
                            componentType={ComponentType.Button}
                            displayModalWhenNotInRange={true}
                        />
                    )}
                    <View style={styles.buttonRow}>
                        <Button center textProps={{ h3: true }} style={styles.button1} onPress={onDonePress}>
                            {Strings.done}
                        </Button>
                        <Button
                            plain
                            border
                            center
                            textProps={{ h5: true, style: widthFixTextStyle }}
                            style={styles.button2}
                            disabled={isFavourite}
                            onPress={onSaveFavouritePark}
                        >
                            {Strings.save_to_favourites}
                        </Button>
                    </View>
                    <View style={styles.receipt}>
                        <Text h2>{Strings.your_receipt}</Text>
                        <CarParkNameRow displayName={park?.displayName} />
                        <TableRow
                            iconLeft={"pinlocation2filled"}
                            label={Strings.location}
                        >
                            <Text small numberOfLines={2}>
                                {address}
                            </Text>
                        </TableRow>
                        <TableRow
                            iconLeft={"stopwatchfilled"}
                            label={Strings.session_started}
                        >
                            {startedAtFormatted}
                        </TableRow>
                        <TableRow
                            iconLeft={"tickfilled"}
                            label={Strings.session_ended}
                        >
                            {endedAtFormatted}
                        </TableRow>
                        <SessionCharges
                            currencyCode={currencyCode}
                            parkSession={session}
                            finishParkingData={props.finishParkingData}
                        />
                        {!!bay.signage && (
                            <TableRow
                                iconLeft="baysign"
                                label={bay.tandemPod ? Strings.tandem_parking.tandem_bays : Strings.bay_number}
                                renderChildrenAsObject
                            >
                                <BayComponent
                                    signage={bay.tandemPod ? tandemPod?.name ?? "" : bay.signage}
                                    displayEvIcon={isEV}
                                    displayMotorbikeIcon={isMotorbike}
                                    backgroundStyles={{backgroundColor}}
                                />
                            </TableRow>
                        )}
                        <TableRow iconLeft={"car"} label={Strings.vehicle} textProps={{ rego: true }}>
                            {registration}
                        </TableRow>
                        {(session.transactionAmount ?? 0) > 0 && session.transactionSuccess && (
                            <>
                                <TableRow
                                    iconLeft={"shieldwithtick"}
                                    label={Strings.credit_card}
                                >
                                    {!!card ? `${Strings.card_ending_in} XXXX ${card.last4}` : Strings.loading}
                                </TableRow>
                                <TableRow
                                    iconLeft={"paperplane"}
                                    contentRight={
                                        <View
                                            style={{
                                                backgroundColor: Colours.ORANGE,
                                                marginLeft: 50,
                                                paddingVertical: 12,
                                                paddingHorizontal: 15,
                                                borderRadius: 3,
                                            }}
                                        >
                                            <Text small>{Strings.receipt_has_been_sent}</Text>
                                        </View>
                                    }
                                />
                            </>
                        )}
                    </View>
                    <View
                        style={[{ flexDirection: "row", width: "100%", paddingTop: 45, marginBottom: 40 }, { bottom }]}
                    >
                        <Button
                            plain
                            border
                            center
                            textProps={{ h5: true }}
                            style={styles.button1}
                            onPress={onDonePress}
                        >
                            {Strings.done}
                        </Button>
                        <Button
                            plain
                            border
                            center
                            textProps={{ h5: true }}
                            style={styles.button2}
                            disabled={isFavourite}
                            onPress={onSaveFavouritePark}
                        >
                            {Strings.save_to_favourites}
                        </Button>
                    </View>
                </View>
                {!!park && <SaveFavourite park={park} saveFavouriteCallback={saveFavouriteCallback} />}
                {!!userOptions && <AppReview userOptions={userOptions} />}
            </View>
        </ParkableBaseView>
    );
}

const actions = {
    setFinishedParkingData,
    showSaveFavouriteModal,
};

const getProps = (state: IRootReducer) => {
    const tokenObject = {
        firebaseToken: state.auth.fireBaseToken,
    };

    return {
        currentSession: state.parking.currentSession,
        finishParkingData: state.parking.finishParkingData,
        userId: state.data.userId,
        territories: (state.territories || {}).territories || {},
        parks: state.parks.parks,
        dateformat: state.settings.dateformat,
        api: state.data.api,
        token: tokenObject,
        favouriteParks: state.userOptions.favouriteParks,
        vehicles: state.user.vehicles,
    };
};

export default connect(getProps, actions)(SessionSummary) as any as React.FunctionComponent<SessionSummaryProps>;

export const SessionSummaryRoute = createRoute({
    path: Routes.SessionSummary,
});
