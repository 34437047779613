import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    AppState,
    AppStateStatus,
    BackHandler,
    NativeScrollEvent,
    NativeSyntheticEvent,
    Platform,
    ScrollView,
    StyleSheet,
    View,
} from "react-native";
import Button from "react/parkable-components/button/Button";
import Colours from "react/parkable-components/styles/Colours";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import { IconName } from "react/parkable-components/icon/Icons";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Text from "react/parkable-components/text/Text";
import Dialog from "react/components/dialog/Dialog";
import { getUserCards, getUserVehicles, setUser } from "../../redux/actions/user";
import { connect } from "react-redux";
import localizeCurrency from "../../constants/localization/localizeCurrency";
import AvailabilityTableRow from "./AvailabilityTableRow";
import { Routes } from "react/navigation/root/root.paths";
import Strings from "../../constants/localization/localization";
import DriveTimeTableRow from "./DriveTimeTableRow";
import { Bay, Feature } from "../../model/Bay";
import { IRootReducer } from "../../redux/reducers/main";
import { cancelReservation, getCurrentParkingSession, startParking } from "../../redux/actions/parking";
import {
    createUpdateParkAvailabilityNotification,
    deleteParkAvailabilityNotification,
    getBayGroupsInPark,
    getParkAvailabilityNotificationForPark,
    getUserBaysWithVehicleFeatures,
} from "../../redux/actions/parks";
import _ from "lodash";
import { Vehicle } from "../../model/Vehicle";
import { AvailableBays, calculateBaysAvailable } from "./calculateBaysAvailable";
import {
    futureParkingButtonStatus,
    startParkingButtonStatus,
    StartParkingButtonStatus,
    StartParkingButtonStatusProps,
} from "./startParkingButtonStatus";
import OpeningHoursComponent from "./OpeningHoursComponent";
import { retrievedParkBays } from "../../redux/actions/sensors";
import { updateUserVehicleAPI } from "../../api/user";
import { parkReservationTime, vehicleFeatureMatch, wordCount } from "../../constants/Util";
import SaveFavourite from "./SaveFavourite";
import DeleteFavourite from "./DeleteFavourite";
import { showDeleteFavouriteModal, showSaveFavouriteModal } from "../../redux/actions/userOptions";
import ReservationTableRow from "./ReservationTableRow";
import { ParkingActivity, ParkingType } from "../../model/Types";
import SupportFooterView from "../common/SupportFooterView";
import { RemainingBaysTableRow } from "./RemainingBaysTableRow";
import AccessGateComponent, { ComponentType } from "../accessControl/AccessGateComponent";
import SubscriptionTableRow from "./SubscriptionTableRow";
import CasualCharge from "./CasualCharge";
import { getSubscriptionInPark } from "../../constants/getActiveSubscriptions";
import ParkingTypeButtonsGroup from "../map/ParkingTypeButtonsGroup";
import { ParkSessionDTO, ParkSessionDTOLocal } from "../../model/ParkSessionDTO";
import { retrieveLargestDiscountVoucher } from "../../redux/actions/vouchers";
import { ActivityType } from "../../model/Voucher";
import { ParkableError } from "../../model/ParkableError";
import Modal from "react-native-modal";
import { BayDTO } from "../../model/BayDTO";
import { setParkingType } from "../../redux/actions/map";
import ParkInstructions from "../common/ParkInstructions";
import { Token } from "../../api/rest";
import { getOrganisation } from "../../redux/actions/organisations";
import ParkImagesCarousel from "../common/ParkImagesCarousel";
import { logEvent } from "react/analytics";
import NoBaysAvailableComponent from "./NoBaysAvailableComponent";
import * as Push from "../../navigation/pushNotifications/constants";
import { addNotificationListener } from "../../navigation/pushNotifications/notificationListener";
import { setBaysAvailableForVehicleRedux } from "../../redux/reducers/confirmStartParkingView";
import { RequestState, useParkingRequestOptions } from "../../api/parkingRequestOptions/parkingRequestOptions.api";
import { useCampusesInOrganisation } from "../../api/campus/campus.api";
import { usePark } from "../../api/park";
import { useTerritory } from "../../api/territory/territory.api";
import { onParkTomorrowPress } from "../../constants/onParkTomorrowPress";
import { useParkingRequestsForUser } from "../../api/parking-request/parking-request.api";
import { useCurrentUser, useUserRoles } from "../../api/user/user.api";
import useShowOpenGateCallback from "../../constants/accessControlConstants";
import { userIsOrgMember, userIsParkableAdmin, userIsSensorAdmin } from "../../constants/getUserMember";
import PreferredBaysRow from "../preferredBays/PreferredBaysRow";
import { createRoute, NavigationProps, useNavigation } from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";
import { PADDING } from "../../root/root.constants";
import useLayoutRef from "../../constants/useLayoutRef";
import { useVehicle } from "../../api/vehicle/vehicle.api";
import { MAX_WIDTH_WEB } from "react/root/container/container";
import { ConfirmStartParkingParams } from "./confirmStartParking/ConfirmStartParkingView";
import { useParkingPrice } from "react/api/parkingPrice/parkingPrice.api";
import { isCreditCardRequired } from "../../services/parkingPrice.service";
import { useActiveParkSession } from "react/api/parkSession/parkSession.api";
import { useSharingPoolBaysForUser } from "react/api/sharingPool/sharingPool.api";
import LocationCard from "../common/maps/LocationCard_root";
import { hasPermissionToFeature } from "../../constants/security/permission";
import { handleFailedTransaction } from "react/constants/ExceptionHandler";
import { BayGroupType } from "../../model/BayGroupType";
import { useBaysAvailableToUser } from "react/api/bay/bay.api";
import { ActivityOrigin } from "react/model/ActivityOrigin";
import { useBayGroupsInPark, useBayGroupsMemberInPark } from "react/api/bayGroup/bayGroup.api";
import { useOrganisation } from "../../api/organisation/organisation.api";
import { EmployeeSubscriptionDTO } from "../../dto/EmployeeSubscriptionDTO";
import { mapBayToBayWithSharingPool } from "../common/bay/util";

type DetailViewProps = ReturnType<typeof getReduxProps> & typeof actions;

function ParkDetailView(props: DetailViewProps) {
    const reserveBayDialogRef = useRef<DialogRef | null>(null);
    const reserveBayErrorDialogRef = useRef<DialogRef | null>(null);
    const reservationStartedDialogRef = useRef<DialogRef | null>(null);
    const scrollRef = useRef<ScrollView>(null);

    const { park } = usePark(props.parkId);
    const { park: ownerPark } = usePark(park?.id, park?.ownerOrganisation);
    const { pricePeriods } = useParkingPrice(park?.parkingPrice);

    const { territory } = useTerritory(park?.territory);
    const { parkSession: currentParksession } = useActiveParkSession();
    const navigation = useNavigation();

    const {
        loadVehicles,
        getUserCards,
        parkId,
        mapPark,
        bay,
        userVehicles,
        bays,
        bayGroups,
        mapPreferences,
        getUserBaysWithVehicleFeatures,
        getCurrentParkingSession,
        currentVehicle,
        hasCard,
        hasVehicle,
        currentSession,
        retrievedParkBays,
        getBayGroupsInPark,
        token,
        initialParkingType,
        serverUrl,
        showSaveFavouriteModal,
        showDeleteFavouriteModal,
        startParking,
        hasReservationInPark,
        favouriteParks,
        favouriteParksByChoice,
        retrieveLargestDiscountVoucher,
        casualVoucher,
        organisations,
        getOrganisation,
        setBaysAvailableForVehicleRedux,
    } = props;

    const { userRoles } = useUserRoles();

    const loading = !park || !bayGroups || !userRoles;
    const allBays: undefined | BayDTO[] = useMemo(() => {
        if (!!bays) {
            return Object.values(bays);
        }
        return undefined;
    }, [bays, parkId]);
    const { bays: baysAvailableToUser } = useBaysAvailableToUser(parkId, { origin: ActivityOrigin.Application });

    const [baysAvailableForVehicle, setBaysAvailableForVehicle] = useState<undefined | BayDTO[]>(undefined);
    const [startParkingButtonDisabled, setStartParkingButtonDisabled] = useState(true);
    const [startParkingButtonText, setStartParkingButtonText] = useState(Strings.loading);
    const [selectedVehicleFeature, setSelectedVehicleFeature] = useState(Feature.SEDAN);
    const [startParkingButtonIcon, setStartParkingButtonIcon] = useState("car");
    const [openingHoursLayout, onOpeningHoursLayout] = useLayoutRef();
    const [selectedParkingType, setSelectedParkingType] = useState<ParkingType>(initialParkingType);
    const [displayOpenGateButton, setDisplayOpenGateButton] = useState(false);
    const [scrollPromptOpacity, setScrollPromptOpacity] = useState(1);
    const [reservationErrorString, setReservationErrorString] = useState("");
    const [reservationButtonText, setReservationButtonText] = useState(Strings.reserve_bay);
    const [reservationButtonRed, setReservationButtonRed] = useState(false);
    const [addingVehicleForReservation, setAddingVehicleForReservation] = useState(false);
    const [reservationButtonSpinner, setReservationButtonSpinner] = useState(false);
    const [startButtonSubtext, setStartButtonSubtext] = useState<string | undefined>(undefined);
    const [baysAvailableCount, setBaysAvailableCount] = useState<number | undefined>(undefined);
    const [subscriptionInPark, setSubscriptionInPark] = useState<EmployeeSubscriptionDTO | undefined>();
    const [parkAddress, setParkAddress] = useState<undefined | string>(undefined);
    const [reserveParking, setReserveParking] = useState(false);
    const [showPayingTwiceWarning, setShowPayingTwiceWarning] = useState(false);
    const [reservationButtonDisabled, setReservationButtonDisabled] = useState(false);
    const [refresh, setRefresh] = useState<undefined | Date>(undefined);
    const isMotorBikeDefault = currentVehicle?.feature === Feature.MOTORBIKE;

    const { user, isLoading: isLoadingUser } = useCurrentUser();
    const isAdmin = userIsParkableAdmin(userRoles);
    const isBaySensorAdministrator = isAdmin || userIsSensorAdmin(userRoles);
    const currentVehicleId = user?.vehicleId;

    const { parkingRequests } = useParkingRequestsForUser(user?.id);

    const { bayGroups: bayGroupsUserIsMemberOf } = useBayGroupsMemberInPark(park?.organisation, park?.id);

    const { campuses } = useCampusesInOrganisation(park?.ownerOrganisation);
    const { organisation: ownerOrg } = useOrganisation(park?.ownerOrganisation);
    const campus = campuses?.find((c) => c.parks.includes(parkId));
    const { vehicle, isLoading: isLoadingVehicle } = useVehicle(currentVehicleId);
    const isLoading = isLoadingUser || isLoadingVehicle;

    const { options, loading: isLoadingBookingInAdvanceSettings } = useParkingRequestOptions(
        park?.organisation,
        parkId,
        campus?.id,
        campus?.organisation
    );
    const { state } = options ?? {};

    const isFutureBooking = state === RequestState.FutureBooking;
    const allowAdvanceBooking = state && state !== RequestState.Disabled;

    const casualAndSubscriptionParkingOff = park?.hideCasualBays && park?.hideSubscriptionBays && park?.hideEVBays;

    const parkingType =
        selectedParkingType === ParkingType.LONG_TERM ? ParkingActivity.LongTerm : ParkingActivity.Casual;

    const availabilityHelper = useMemo(
        () =>
            park && !isLoadingBookingInAdvanceSettings
                ? futureParkingButtonStatus(park, subscriptionInPark, isFutureBooking)
                : undefined,
        [park, isLoadingBookingInAdvanceSettings, subscriptionInPark, isFutureBooking]
    );

    const futureBookingDisabled =
        (availabilityHelper || { disableFutureParkButton: true }).disableFutureParkButton ||
        (selectedParkingType === ParkingType.ELECTRIC && park?.allowEVReservations !== true) ||
        !allowAdvanceBooking ||
        isLoading;

    const organisation = useMemo(() => {
        if (!!park && !!park.organisation && !!organisations) {
            return organisations[park.organisation];
        }
    }, [park, organisations]);

    const hasPermissionToCreatePrivateCasual =
        !!user &&
        !!userRoles &&
        !!park?.organisation &&
        hasPermissionToFeature(user, userRoles, "app.has.permission.to.casual.session", {
            organisationId: park.organisation,
        });

    const { bays: sharingPoolBays, isLoading: isSharingPoolBaysLoading } = useSharingPoolBaysForUser(
        organisation?.id,
        park?.id,
        { feature: currentVehicle?.feature }
    );

    const { bayGroups: ownerBayGroups } = useBayGroupsInPark(park?.ownerOrganisation, parkId);

    const canBookInSharingPool = !!(
        ownerPark?.futureBookingInSharingPoolEnabled &&
        sharingPoolBays?.some((b) => b.organisation === park?.ownerOrganisation)
    );

    const showParkingInAdvanceButton =
        hasPermissionToCreatePrivateCasual &&
        (!organisation?.electricChargingEnabled || selectedParkingType !== ParkingType.ELECTRIC) &&
        allowAdvanceBooking &&
        !!(bayGroupsUserIsMemberOf?.length || canBookInSharingPool);

    useEffect(() => {
        if (!!park && !!park.organisation && !organisation) {
            getOrganisation(park.organisation);
        }
    }, [park, parkId]);

    useEffect(() => {
        getCurrentParkingSession();
        loadVehicles();
        getUserCards();
        retrieveLargestDiscountVoucher(parkId, ActivityType.Casual);
        retrieveLargestDiscountVoucher(parkId, ActivityType.LongTerm);
    }, [parkId]);

    useEffect(() => {
        if (!!park) {
            setReservationButtonDisabled(
                (selectedParkingType === ParkingType.ELECTRIC && park.allowEVReservations !== true) ||
                    (startParkingButtonDisabled && !hasReservationInPark)
            );
        }
    }, [startParkingButtonDisabled, hasReservationInPark, park, selectedParkingType]);

    useEffect(() => {
        if (!!park) {
            if (park?.organisation !== null) {
                getUserBaysWithVehicleFeatures(
                    park.id,
                    [Feature.ELECTRIC, Feature.MOTORBIKE, Feature.SEDAN],
                    parkingType
                );
                getBayGroupsInPark(park.id, park.organisation);
            }

            setParkAddress(park.address);
            retrieveLargestDiscountVoucher(park.id, ActivityType.Casual);
            retrieveLargestDiscountVoucher(park.id, ActivityType.LongTerm);
        }
    }, [park?.id, park?.organisation, currentVehicle, refresh, selectedParkingType]);

    useEffect(() => {
        const backHandler = BackHandler.addEventListener("hardwareBackPress", () => handleBackPress("hardware"));
        return () => {
            backHandler.remove();
            BackHandler.removeEventListener("hardwareBackPress", () => handleBackPress("hardware"));
        };
    }, [selectedParkingType]);

    const handleBackPress = (source: string) => {
        props.setParkingType(selectedParkingType);

        if (source === "toolbar") {
            if (navigation.getState().index === 0) {
                navigation.reset({
                    routes: [
                        {
                            name: Routes.ParkableMapView,
                            params: {},
                        },
                    ],
                });
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const onAppStateChange = useCallback((nextAppState: AppStateStatus) => {
        if (nextAppState === "active") {
            getCurrentParkingSession();
        }
    }, []);

    useEffect(() => {
        const subscription = AppState.addEventListener("change", onAppStateChange);

        return () => {
            subscription.remove();
        };
    }, []);

    const baysAvailable: undefined | AvailableBays = useMemo(() => {
        if (!!park) {
            if (!!park.organisation && (bayGroups === undefined || allBays === undefined)) {
                return undefined;
            }
            const isPublicParker = !userIsOrgMember(userRoles, park?.organisation);
            return calculateBaysAvailable(
                isPublicParker,
                park,
                bayGroups,
                ownerBayGroups ?? [],
                allBays ?? [],
                sharingPoolBays ?? [],
                currentParksession
            );
        }
    }, [park?.id, bayGroups, allBays, bay, currentParksession, sharingPoolBays]);

    useEffect(() => {
        if (!park || !territory || !baysAvailable || isSharingPoolBaysLoading) {
            return;
        }
        let baysForVehicle: BayDTO[];
        switch (selectedParkingType) {
            case ParkingType.ELECTRIC:
                baysForVehicle = baysAvailable.electricBays;
                break;
            case ParkingType.LONG_TERM:
                if (currentVehicle?.feature === Feature.MOTORBIKE) {
                    baysForVehicle =
                        baysAvailable.motorbikeLongTermBays.length > 0
                            ? baysAvailable.motorbikeLongTermBays
                            : baysAvailable.sedanLongTermBays;
                } else {
                    baysForVehicle = baysAvailable.sedanLongTermBays;
                }
                break;
            case ParkingType.CASUAL:
            default:
                if (currentVehicle?.feature === Feature.MOTORBIKE) {
                    baysForVehicle =
                        baysAvailable.motorbikeCasualBays.length > 0
                            ? baysAvailable.motorbikeCasualBays
                            : baysAvailable.sedanCasualBays;
                } else {
                    baysForVehicle = baysAvailable.sedanCasualBays;
                }
                break;
        }

        setBaysAvailableForVehicle(baysForVehicle);
        setBaysAvailableForVehicleRedux(baysForVehicle);

        const params: StartParkingButtonStatusProps = {
            activeSession: currentSession,
            activeSubscriptionInPark: subscriptionInPark,
            parkingType: selectedParkingType,
            availableBays: baysForVehicle ?? [],
            park: park,
            loading: loading,
            isMotorBikeDefault,
            bayGroups,
            territory,
        };
        const parkingButtonStatus: StartParkingButtonStatus = startParkingButtonStatus(params);
        setStartParkingButtonText(parkingButtonStatus.buttonText);
        setStartParkingButtonDisabled(parkingButtonStatus.disabled);
        setStartButtonSubtext(parkingButtonStatus.buttonSubtext || undefined);
        setStartParkingButtonIcon(parkingButtonStatus.buttonIcon);

        setBaysAvailableCount(baysForVehicle.length);
    }, [
        park?.id,
        territory,
        currentSession,
        bay,
        selectedParkingType,
        baysAvailable,
        currentVehicle,
        isSharingPoolBaysLoading,
    ]);

    useEffect(() => {
        if (currentVehicleId && userVehicles) {
            const feature =
                (_.first(userVehicles.filter((v) => v.id === props.currentVehicleId)) || {}).feature || Feature.SEDAN;
            if (!(!!park && !!park.organisation)) {
                setSelectedVehicleFeature(Feature.SEDAN);
            } else {
                setSelectedVehicleFeature(feature);
            }
        }

        if (addingVehicleForReservation && !!currentVehicleId) {
            setAddingVehicleForReservation(false);
            startReservation();
        }
    }, [userVehicles, currentVehicleId]);

    useShowOpenGateCallback(setDisplayOpenGateButton, parkId);

    useEffect(() => {
        if (hasReservationInPark) {
            //user has a reservation here
            setReservationButtonText(Strings.cancel_reservation);
            setReservationButtonRed(true);
        } else {
            setReservationButtonText(Strings.reserve_bay);
            setReservationButtonRed(false);
        }
    }, [hasReservationInPark]);

    const isFavourite = !!_.values(favouriteParks || {}).find((p) => p.id === park?.id);
    const isFavouriteByChoice = !!_.values(favouriteParksByChoice || {}).find((p) => p.id === park?.id);

    const onStartParkingPress = useCallback(
        (startParking: boolean) => {
            if (selectedParkingType === ParkingType.LONG_TERM) {
                const assignedBay = !!park?.organisation ? baysAvailableForVehicle?.[0] : bay;

                navigation.push(Routes.SelectSubscriptionPlanView, {
                    parkId,
                    bayId: assignedBay?.id,
                    feature: selectedVehicleFeature,
                    baysAvailable: baysAvailableForVehicle ?? [],
                });
            } else {
                if (subscriptionInPark && !startParking && !hasReservationInPark) {
                    setShowPayingTwiceWarning(true);
                    return;
                } else if (selectedParkingType === ParkingType.ELECTRIC) {
                    navigation.push(Routes.SelectBayView, {
                        baysAvailable: mapBayToBayWithSharingPool(baysAvailableForVehicle, sharingPoolBays) ?? [],
                        onBaySelected: onEvBaySelected,
                        parkId: parkId,
                        parkingType: selectedParkingType,
                    });
                } else {
                    const paramsEvent = { userId: `${user?.id ?? 0}`, parkId: `${parkId}` };
                    logEvent(undefined, "parkdetails_startparking", paramsEvent);

                    const partialParams = {
                        parkId,
                        park,
                        feature: selectedVehicleFeature,
                        checkShouldUpdateVehicle,
                        parkingType: selectedParkingType,
                        holdBay: true,
                        session: null as null | ParkSessionDTOLocal,
                    };

                    if (hasReservationInPark) {
                        navigation.push(Routes.ConfirmStartReservationView, { session: currentSession });
                        return;
                    }

                    const creditCardRequired =
                        park && pricePeriods && isCreditCardRequired(park, pricePeriods, hasCard, casualVoucher);

                    if (!creditCardRequired && hasVehicle) {
                        //case #1
                        const params: ConfirmStartParkingParams = {
                            ...partialParams,
                        };
                        navigation.push(Routes.ConfirmStartParkingView, params);
                    } else {
                        const destination = {
                            route: Routes.ConfirmStartParkingView,
                            params: {
                                ...partialParams,
                                hasVehicle,
                                hasCard,
                            },
                        };
                        if (creditCardRequired) {
                            //case #2
                            navigation.push(Routes.AddNewCard, { destination });
                        } else if (!hasVehicle) {
                            //case #3
                            navigation.push(Routes.AddNewVehicleView, { destination });
                        }
                    }
                }
            }
        },
        [
            casualVoucher,
            hasCard,
            hasVehicle,
            navigation,
            baysAvailableForVehicle,
            bay,
            bayGroups,
            park,
            selectedParkingType,
            selectedVehicleFeature,
            currentVehicle,
            hasReservationInPark,
            subscriptionInPark,
        ]
    );

    useEffect(() => {
        const subscriptionInPark = getSubscriptionInPark(Object.values(props.employeeSubscriptions), parkId);

        setSubscriptionInPark(subscriptionInPark);
    }, [props.employeeSubscriptions, parkId]);

    const onEvBaySelected = useCallback(
        (selectedBay: Bay) => {
            const _baysAvailable = baysAvailable?.electricBays || new Array<BayDTO>();

            if (hasCard && hasVehicle) {
                //case #1
                navigation.push(Routes.ConfirmStartParkingView, {
                    parkId: props.parkId,
                    feature: selectedVehicleFeature,
                    checkShouldUpdateVehicle,
                    parkingType: selectedParkingType,
                    bay: selectedBay,
                    holdBay: true,
                });
            } else {
                const destination = {
                    route: Routes.ConfirmStartParkingView,
                    params: {
                        parkId: park?.id,
                        feature: selectedVehicleFeature,
                        checkShouldUpdateVehicle,
                        parkingType: selectedParkingType,
                        baysAvailable: _baysAvailable,
                        hasVehicle,
                        hasCard,
                        bay: selectedBay,
                        holdBay: true,
                    },
                };
                if (!hasCard) {
                    //case #2
                    navigation.push(Routes.AddNewCard, { destination });
                } else {
                    //case #3
                    navigation.push(Routes.AddNewVehicleView, { destination });
                }
            }
        },
        [park, baysAvailable, hasCard, hasVehicle, selectedVehicleFeature, selectedParkingType]
    );

    // TODO - EXPO change to custom hook
    function checkShouldUpdateVehicle(): Promise<any> {
        if (!user) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            if (selectedVehicleFeature) {
                //check if currentVehicle has this vehicle feature
                const userVehicles = props.userVehicles || new Array<Vehicle>();
                const currentlySelected = _.first(userVehicles.filter((v) => v.id === currentVehicleId)) || {};
                if (!vehicleFeatureMatch(currentlySelected, selectedVehicleFeature)) {
                    //get another vehicle with the feature
                    const vehicleWithFeature = _.first(
                        userVehicles.filter((v) => vehicleFeatureMatch(v, selectedVehicleFeature))
                    );

                    if (vehicleWithFeature) {
                        updateUserVehicleAPI(serverUrl, token, user.id, vehicleWithFeature.id).then(
                            (r: any) => {
                                setUser(r.user);
                                resolve(r.user.vehicleId);
                            },
                            (err: any) => {
                                reject(err);
                            }
                        );

                        return;
                    }
                }
            }
            resolve(currentVehicleId);
        });
    }

    const onReserveParkPress = useCallback(
        (reserveParking: boolean) => {
            if (subscriptionInPark && !reserveParking) {
                setReserveParking(true);
                setShowPayingTwiceWarning(true);
                return;
            }

            if (!reservationButtonSpinner) {
                reserveBayDialogRef.current?.show();
            }
        },
        [reservationButtonSpinner, reserveBayDialogRef, subscriptionInPark]
    );

    const onPositiveReserveBayDialogPress = () => {
        if (hasReservationInPark) {
            cancelReservation();
        } else {
            const creditCardRequired =
                park && pricePeriods && isCreditCardRequired(park, pricePeriods, hasCard, casualVoucher);
            const evBay = baysAvailable?.electricBays[0];

            if (!creditCardRequired && hasVehicle) {
                //case #1
                setReservationButtonSpinner(true);
                startParking(
                    parkId,
                    selectedParkingType === ParkingType.ELECTRIC ? evBay?.id : undefined,
                    true,
                    undefined,
                    territory,
                    hasCard,
                    null,
                    reservationStarted,
                    errorStartingReservation
                );
            } else {
                if (creditCardRequired) {
                    //case #2
                    navigation.push(Routes.AddNewCard, {});
                } else if (!hasVehicle) {
                    //case #3
                    navigation.push(Routes.AddNewVehicleView, {});
                }
            }
        }
    };

    const startReservation = useCallback(() => {
        startParking(
            parkId,
            undefined,
            true,
            undefined,
            territory,
            hasCard,
            null,
            reservationStarted,
            errorStartingReservation
        );
    }, [parkId, territory]);

    const errorStartingReservation = useCallback(
        (err: ParkableError | undefined) => {
            setReservationButtonSpinner(false);
            if (!err || !handleFailedTransaction(err, navigation)) {
                setReservationErrorString(err?.message ?? Strings.internal_error_if_persists);
                reserveBayErrorDialogRef.current?.show();
            }
        },
        [reserveBayErrorDialogRef]
    );

    const reservationStarted = useCallback(
        (session: ParkSessionDTO) => {
            setReservationButtonSpinner(false);
            const params = {
                park,
                feature: selectedVehicleFeature,
                checkShouldUpdateVehicle,
                parkingType: selectedParkingType,
                session: session,
            };
            navigation.push(Routes.ConfirmStartReservationView, { ...params });
        },
        [park]
    );

    const cancelReservation = useCallback(() => {
        if (!currentSession) {
            return;
        }
        props.cancelReservation(
            currentSession.id,
            parkId,
            () => {},
            () => setReservationButtonSpinner(false)
        );
    }, [currentSession]);

    const onPFBParkTomorrowPress = useCallback(() => {
        void onParkTomorrowPress(park?.id, park?.organisation, campus?.id, user?.id, parkingRequests ?? [], navigation);
    }, [park, parkingRequests]);

    const onFutureBookingPress = useCallback(() => {
        const destination = {
            route: Routes.FutureBookingView,
            params: {
                parkId,
                campusId: campus?.id,
                organisationId: park?.organisation,
            },
        };
        if (!user?.vehicleId || vehicle?.deleted) {
            // @ts-ignore
            navigation.push(Routes.AddNewVehicleView, destination);
        } else if (user?.vehicleId && !!vehicle && !vehicle.deleted && park?.organisation) {
            navigation.push(Routes.FutureBookingView, {
                parkId,
                campusId: campus?.id,
                organisationId: park?.organisation,
            });
        }
    }, [user, vehicle, park, campus]);

    const onAssignSensor = useCallback(() => {
        retrievedParkBays(parkId);
        navigation.push(Routes.AssignSensorBayList, { parkId });
    }, [parkId]);

    // noinspection JSUnusedLocalSymbols
    const onScroll = useCallback(
        (evt?: NativeSyntheticEvent<NativeScrollEvent>) => {
            setScrollPromptOpacity(scrollPromptOpacity - 0.2 < 0 ? 0 : scrollPromptOpacity - 0.2);
        },
        [scrollPromptOpacity]
    );

    const saveFavourite = useCallback(() => {
        showSaveFavouriteModal(true);
    }, []);

    const deleteFavourite = useCallback(() => {
        showDeleteFavouriteModal(true);
    }, []);

    const changeParkingType = useCallback((t: ParkingType) => {
        scrollRef.current?.scrollTo({ y: 0, x: 0, animated: false });
        setSelectedParkingType(t);
        props.setParkingType(t);
    }, []);

    const closeModal = () => {
        setReserveParking(false);
        setShowPayingTwiceWarning(false);
    };

    const onContinue = () => {
        setShowPayingTwiceWarning(false);

        if (reserveParking) {
            setReserveParking(false);
            reserveBayDialogRef.current?.show();
        } else {
            onStartParkingPress(true);
        }
    };

    const onNotificationReceived = useCallback((code: string) => {
        if (code === Push.ParkAvailabilityNotificaiton) {
            setRefresh(new Date());
        }

        return false;
    }, []);

    const onAvailabilityPress = () => {
        if (openingHoursLayout) {
            scrollRef.current?.scrollTo({
                y: openingHoursLayout.y,
                animated: true,
            });
        }
    };

    const hasPermissionToCreatePrivateSubscriptions =
        !!user &&
        !!userRoles &&
        !!park?.organisation &&
        hasPermissionToFeature(user, userRoles, "app.has.permission.to.subscriptions", {
            organisationId: park.organisation,
        });
    const parkHasPublicSubscriptionBayGroups = bayGroups?.some(
        (bg) => bg.groupType === BayGroupType.PublicSubscription
    );
    const parkHasPublicCasualBayGroups = bayGroups?.some((bg) => bg.groupType === BayGroupType.PublicDynamic);
    const allBaysAvailableToUser = [...(baysAvailableToUser ?? []), ...(sharingPoolBays ?? [])];
    const parkHasElectricBays = allBaysAvailableToUser.some((b) => _.includes(b.features, Feature.ELECTRIC));

    useEffect(() => {
        const notifListener = addNotificationListener(onNotificationReceived, "ParkDetailView_new");

        return () => {
            notifListener.remove();
        };
    }, []);

    return (
        <ParkableBaseView scrollable={false} removeStandardMargins toolbarStyle={styles.toolbar} loading={loading}>
            <ScrollView ref={scrollRef} onScroll={onScroll} style={styles.main}>
                <View>
                    {!!parkAddress ? (
                        <>
                            {!!organisation?.name && !park?.hideOrganisationName && (
                                <Text numberOfLines={2} grey h3 style={[{ marginBottom: 0, ...styles.title }]}>
                                    {organisation?.name}
                                </Text>
                            )}
                            {!!park?.displayName && (
                                <Text
                                    numberOfLines={2}
                                    h1
                                    style={{
                                        marginBottom: 35,
                                        ...(!!organisation?.name && !park?.hideOrganisationName ? {} : styles.title),
                                    }}
                                >
                                    {park?.displayName}
                                </Text>
                            )}
                            {!park?.displayName && (
                                <Text
                                    numberOfLines={2}
                                    h1
                                    style={{ marginBottom: 35, ...(!!organisation?.name ? {} : styles.title) }}
                                >
                                    {parkAddress}
                                </Text>
                            )}
                        </>
                    ) : (
                        <View style={{ marginTop: 87, marginBottom: 37 }} />
                    )}
                </View>

                {organisation && park && allBays && (
                    <ParkingTypeButtonsGroup
                        isMotorBikeDefault={isMotorBikeDefault}
                        displayCasual={
                            !park.hideCasualBays &&
                            (hasPermissionToCreatePrivateCasual ||
                                parkHasPublicCasualBayGroups ||
                                park?.sharingPoolPublic)
                        }
                        displaySubscription={
                            !park.hideSubscriptionBays &&
                            (parkHasPublicSubscriptionBayGroups || hasPermissionToCreatePrivateSubscriptions)
                        }
                        displayEV={!park.hideEVBays && !!ownerOrg?.electricChargingEnabled && parkHasElectricBays}
                        onChangeParkingType={changeParkingType}
                        currentParkingType={selectedParkingType}
                    />
                )}

                {hasReservationInPark && selectedParkingType !== ParkingType.LONG_TERM && (
                    <ReservationTableRow reservationMinutes={park?.reservationMinutes} />
                )}

                {!!subscriptionInPark && (
                    <SubscriptionTableRow
                        parkingType={selectedParkingType}
                        subscription={subscriptionInPark}
                        navigation={navigation}
                    />
                )}

                {park && displayOpenGateButton && (
                    <AccessGateComponent
                        showExitGates={true}
                        showEntranceGates={false}
                        displayModalWhenNotInRange={true}
                        parkId={parkId}
                        componentType={ComponentType.TableRow}
                    />
                )}

                <DriveTimeTableRow
                    showDriveTime={false}
                    endLatitude={park?.latitude}
                    endLongitude={park?.longitude}
                    park={park || null}
                />

                <AvailabilityTableRow availability={park?.availability} onPress={onAvailabilityPress} />

                {!casualAndSubscriptionParkingOff && (
                    <RemainingBaysTableRow
                        park={park}
                        availableBayCount={baysAvailableCount}
                        parkingType={selectedParkingType}
                        hideCasualBays={park?.hideCasualBays}
                    />
                )}

                <CasualCharge parkingType={selectedParkingType} park={park} territory={territory} />

                {selectedParkingType === ParkingType.ELECTRIC && (
                    <TableRow
                        label={Strings.electricity_price}
                        textProps={{ small: true }}
                        iconLeft={"electricvehicleplug"}
                    >
                        {localizeCurrency(
                            baysAvailableForVehicle?.[0]?.pricePerKwh ?? park?.pricePerKwh ?? 0,
                            territory?.currencyCode,
                            false
                        ) +
                            " " +
                            Strings.perKwh}
                    </TableRow>
                )}

                {park?.organisation && hasPermissionToCreatePrivateCasual && (
                    <PreferredBaysRow orgId={park.organisation} parkId={parkId} />
                )}

                {(!(selectedParkingType === ParkingType.CASUAL && baysAvailableCount === 0) ||
                    hasReservationInPark ||
                    baysAvailableCount === undefined) &&
                    !casualAndSubscriptionParkingOff && (
                        <Button
                            style={{ marginTop: 35, justifyContent: "space-between" }}
                            onPress={() => onStartParkingPress(false)}
                            disabled={startParkingButtonDisabled}
                            label={startButtonSubtext}
                            large
                            iconLeft={
                                (startButtonSubtext?.length || 0) >= 16
                                    ? undefined
                                    : (startParkingButtonIcon as IconName)
                            }
                            iconRight={!startParkingButtonDisabled ? "arrowboldright" : undefined}
                        >
                            {startParkingButtonText}
                        </Button>
                    )}

                {((selectedParkingType !== ParkingType.LONG_TERM &&
                    !hasReservationInPark &&
                    baysAvailableCount !== undefined &&
                    park) ||
                    (!casualAndSubscriptionParkingOff && park)) && (
                    <NoBaysAvailableComponent
                        baysAvailable={baysAvailableCount}
                        park={park}
                        createUpdateParkAvailabilityNotification={props.createUpdateParkAvailabilityNotification}
                        getParkAvailabilityNotificationForPark={props.getParkAvailabilityNotificationForPark}
                        deleteParkAvailabilityNotification={props.deleteParkAvailabilityNotification}
                    />
                )}

                {selectedParkingType !== ParkingType.LONG_TERM && !casualAndSubscriptionParkingOff && (
                    <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 18 }}>
                        {park?.allowReservations !== false && (
                            <Button
                                disabled={reservationButtonDisabled}
                                loading={reservationButtonSpinner}
                                red={reservationButtonRed}
                                border={!reservationButtonRed}
                                center
                                plain
                                textProps={{
                                    center: true,
                                    h5: true,
                                    white: reservationButtonDisabled || reservationButtonRed,
                                }}
                                style={{ flex: 1 }}
                                onPress={() => onReserveParkPress(false)}
                            >
                                {reservationButtonSpinner ? null : reservationButtonText}
                            </Button>
                        )}
                        {park?.allowReservations !== false &&
                            hasPermissionToCreatePrivateCasual &&
                            (!organisation?.electricChargingEnabled || selectedParkingType !== ParkingType.ELECTRIC) &&
                            allowAdvanceBooking && <View style={styles.spacer} />}
                        {showParkingInAdvanceButton &&
                            (isFutureBooking ? (
                                <Button
                                    center
                                    disabled={futureBookingDisabled}
                                    plain
                                    style={{ flex: 1 }}
                                    border
                                    textProps={{ h5: true }}
                                    onPress={onFutureBookingPress}
                                >
                                    {Strings.future_booking.future_booking}
                                </Button>
                            ) : (
                                <Button
                                    disabled={futureBookingDisabled}
                                    border
                                    center
                                    plain
                                    textProps={{ h5: true }}
                                    style={{ flex: 1 }}
                                    onPress={onPFBParkTomorrowPress}
                                >
                                    {availabilityHelper?.futureParkingButtonText ?? Strings.loading}
                                </Button>
                            ))}
                    </View>
                )}

                {!!park && (
                    <View style={styles.imageScrollView}>
                        <ParkImagesCarousel park={park} />
                    </View>
                )}

                <ParkInstructions
                    title={Strings.parking_instructions}
                    text={park?.description || Strings.default_parking_instructions_string}
                    style={{ marginHorizontal: 0, marginVertical: 0 }}
                    showHeading={true}
                    descriptionTextProps={{ h2: true, style: { marginTop: 27, marginBottom: 13 } }}
                    showCollapsed={park ? wordCount(park?.description) > 100 : true}
                    summaryView={true}
                />

                <View onLayout={onOpeningHoursLayout}>
                    <Text h2 style={{ marginTop: 45, marginBottom: 9 }}>
                        {Strings.opening_hours}
                    </Text>
                </View>

                <OpeningHoursComponent availability={park?.availability} />

                <Text h2 style={{ marginTop: 36, marginBottom: 13 }}>
                    {Strings.location}
                </Text>

                <LocationCard
                    user={user ?? undefined}
                    mapPark={mapPark}
                    parkId={parkId}
                    displayType={mapPreferences.casualDisplayType}
                    orgName={organisation?.name}
                    parkingType={selectedParkingType}
                    bay={(baysAvailableForVehicle || [])[0]}
                    isMotorBikeDefault={isMotorBikeDefault}
                    isHideOrgName={park?.hideOrganisationName}
                />

                <View>
                    <SupportFooterView />
                    {isBaySensorAdministrator && (
                        <TableRow chevron onPress={onAssignSensor}>
                            {Strings.assign_sensor}
                        </TableRow>
                    )}
                </View>

                {!isFavouriteByChoice && (
                    <Button
                        border
                        center
                        plain
                        textProps={{ h5: true, bold: true }}
                        disabled={isFavourite}
                        style={{ marginBottom: 45 }}
                        onPress={() => saveFavourite()}
                    >
                        {Strings.save_to_favourites}
                    </Button>
                )}

                {isFavouriteByChoice && (
                    <Button
                        border
                        center
                        plain
                        textProps={{ h5: true, bold: true }}
                        style={{ marginBottom: 100 }}
                        onPress={() => deleteFavourite()}
                    >
                        {Strings.remove_from_favourites}
                    </Button>
                )}
            </ScrollView>

            <SaveFavourite park={park} />

            <DeleteFavourite park={park} />

            <Dialog
                ref={reserveBayDialogRef}
                label={hasReservationInPark ? Strings.end_reservation : Strings.reserve_bay}
                labelProps={{ style: { color: Colours.NEUTRALS_BLACK, textAlign: "left" } }}
                title={
                    hasReservationInPark
                        ? Strings.confirm_end_reservation
                        : Strings.reserve_bay_description(parkReservationTime(park?.reservationMinutes))
                }
                titleProps={{ style: { textAlign: "left" }, h2: undefined }}
                positiveText={hasReservationInPark ? Strings.ok : Strings.reserve}
                positiveProps={{
                    textProps: { h5: true, style: { color: Colours.NEUTRALS_BLACK } },
                    style: { backgroundColor: Colours.ORANGE },
                }}
                negativeProps={{ textProps: { h5: true, style: { color: Colours.NEUTRALS_BLACK } } }}
                negativeText={Strings.cancel}
                onPositivePress={() => onPositiveReserveBayDialogPress()}
                onNegativePress={() => reserveBayDialogRef.current?.hide()}
            />
            <Dialog
                ref={reserveBayErrorDialogRef}
                label={Strings.reservation_error}
                title={reservationErrorString}
                positiveText={Strings.ok}
                onPositivePress={() => reserveBayDialogRef.current?.hide()}
            />
            <Dialog
                autoHide={true}
                autoHideDuration={1000}
                style={styles.reservationStartedContainer}
                ref={reservationStartedDialogRef}
                iconFABProps={{ style: styles.reservationStartedFab }}
                icon={"tick"}
                iconProps={{ white: true }}
                label={Strings.reservation_has_begun}
                labelProps={{ style: styles.reservationStartedLabel }}
            />
            <Modal
                animationIn={"bounceIn"}
                animationOut={"fadeOut"}
                isVisible={showPayingTwiceWarning}
                style={styles.modal}
            >
                <View style={styles.openView}>
                    <Text h2 style={{ marginBottom: 0 }}>
                        {Strings.you_will_be_paying_twice}
                    </Text>
                    <View style={styles.lineBreak} />
                    <Text small style={styles.textLine}>
                        {Strings.just_drive_into_your_bay}
                    </Text>
                    <Text bold>{Strings.i_knew_that}</Text>
                    <Text small>{Strings.want_to_park_a_second_vehicle}</Text>
                    <View style={styles.modalButtonView}>
                        <Button
                            form
                            center
                            plain
                            border
                            onPress={closeModal}
                            style={{ flex: 1, marginHorizontal: 4.5 }}
                        >
                            {Strings.cancel}
                        </Button>
                        <Button form center onPress={onContinue} style={{ flex: 1, marginHorizontal: 4.5 }}>
                            {Strings.continue}
                        </Button>
                    </View>
                </View>
            </Modal>
        </ParkableBaseView>
    );
}

const getReduxProps = (state: IRootReducer, props: NavigationProps<Routes.ParkDetailView>) => {
    const { parkId, parkingType } = props.route.params;

    const initialParkingType = parkingType || state.maps.preferences.parkingType || ParkingType.CASUAL;
    const mapPark = state.parks.mapParks[parkId];
    const bay = !!state.parks.bays[parkId] ? (Object.values(state.parks.bays[parkId])[0] as Bay) : undefined;
    const bayGroups = (state.parks.bayGroups || {})[parkId];

    const currentVehicle = state.user.vehicles?.find((v) => v.id === state.user.currentVehicleId) || null;
    const userOptions = state.userOptions.userOptions;

    const currentSession = state.parking.currentSession;
    const hasCard = !!state.user.currentCardId;
    const hasVehicle = !!state.user.currentVehicleId || (state.user.vehicles?.length ?? 0) > 0;

    const tokenObject = {
        firebaseToken: state.auth.fireBaseToken,
    } as Token;

    return {
        mapPark,
        bay,
        bays: state.parks.userBays[parkId],
        bayGroups,
        parkId,
        currentVehicleId: state.user.user?.vehicleId,
        userVehicles: state.user.vehicles,
        currentVehicle,
        hasCard,
        hasVehicle,
        currentSession,
        serverUrl: state.data.api,
        token: tokenObject,
        initialParkingType,
        userOptions,
        api: state.data.api,
        mapPreferences: (state.maps || {}).preferences || {},
        hasReservationInPark: !!currentSession && !currentSession.startedAt && currentSession.park === parkId,
        employeeSubscriptions: state.subscriptions.employeeSubscriptions,
        favouriteParks: state.userOptions.favouriteParks,
        favouriteParksByChoice: state.userOptions.favouriteParksByChoice,
        organisations: state.organisations.organisations,
        casualVoucher: state.user?.casualVoucher?.[parkId],
    };
};

const actions = {
    loadVehicles: getUserVehicles,
    getUserCards,
    getUserBaysWithVehicleFeatures,
    getBayGroupsInPark,
    retrievedParkBays,
    showSaveFavouriteModal,
    showDeleteFavouriteModal,
    startParking,
    getCurrentParkingSession,
    cancelReservation,
    retrieveLargestDiscountVoucher,
    setParkingType,
    getOrganisation,
    createUpdateParkAvailabilityNotification,
    getParkAvailabilityNotificationForPark,
    deleteParkAvailabilityNotification,
    setBaysAvailableForVehicleRedux,
};

export class ParkDetailParams {
    parkId: number;
    parkingType?: ParkingType;
}

export default connect(getReduxProps, actions)(ParkDetailView);

export const ParkDetailRoute = createRoute({
    path: Routes.ParkDetailView,
    params: { type: ParkDetailParams },
});

const styles = StyleSheet.create({
    main: {
        paddingHorizontal: PADDING,
    },
    toolbar: {
        paddingHorizontal: PADDING,
    },
    outerFooter: {
        position: "absolute",
        top: 0,
        backgroundColor: "transparent",
        width: "100%",
        zIndex: 100,
    },
    mapLinkTableRow: {
        borderBottomWidth: 1,
        borderBottomColor: Colours.GREY_BORDER,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingVertical: 9,
    },
    imageScrollView: {
        marginTop: 47,
    },
    standardIcon: {
        fontFamily: Platform.OS === "ios" ? "parkable-icon-library" : "parkable-icon-library",
        fontSize: 30,
    },
    reservation: {
        backgroundColor: Colours.ORANGE,
    },
    noBaysBox: {
        backgroundColor: Colours.PINK_DARK,
        width: "100%",
        padding: 18,
        borderColor: Colours.PINK_DARK,
        borderRadius: 3,
        borderWidth: 1,
        marginTop: 27,
        borderStyle: "solid",
    },
    reservationStartedContainer: {
        position: "absolute",
        width: "100%",
    },
    reservationStartedFab: {
        backgroundColor: Colours.GREEN_300,
    },
    reservationStartedLabel: {
        color: Colours.GREEN_300,
    },
    reservationStartedIcon: {
        color: Colours.NEUTRALS_WHITE,
    },
    modal: {
        backgroundColor: "rgba(0,0,0,0.1)",
        ...Platform.select({
            web: {
                maxWidth: MAX_WIDTH_WEB,
                alignSelf: "center",
            },
        }),
    },
    openView: {
        borderWidth: 1,
        borderColor: Colours.GREY_BORDER,
        borderRadius: 3,
        padding: 18,
        backgroundColor: Colours.NEUTRALS_WHITE,
        marginHorizontal: 9,
        marginVertical: 28,
    },
    modalButtonView: {
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 27,
    },
    textLine: {
        marginBottom: 18,
    },
    lineBreak: {
        height: 1,
        width: "100%",
        marginBottom: 18,
        backgroundColor: Colours.GREY_BORDER,
    },
    title: {
        lineHeight: 35,
    },
    spacer: {
        width: 9,
    },
});
